<template>
  <div>Company Content</div>
</template>

<script>
export default {
    name: "Content"
}
</script>

<style>

</style>